<template>

  <div>
    <b-row
      class="content-header"
    >

      <!-- Content Left -->
      <b-col
        class="content-header-left mb-2"
        cols="7"
        md="9"
      >
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <h2 class="custom-header-title float-left pr-1 mb-0">
              Cost Centres
            </h2>
          </b-col>
        </b-row>
      </b-col>

      <!-- Content Right -->
      <b-col
        class="content-header-right text-right mb-1"
        md="3"
        cols="5"
      >
        <b-button
          v-if="canViewThisAction('create', 'CostCenter')"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="success"
          class="mobile_create_button"
          @click="openCreateNewCostCentreModal()"
        >
          <feather-icon
            icon="PlusIcon"
            class="mr-50"
            size="16"
          />
          <span class="align-middle d-md-block d-none">Create New Cost Centre</span>
          <span class="align-middle d-md-none d-block">New</span>
        </b-button>
      </b-col>
    </b-row>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} - {{ dataMeta.to }} of {{ dataMeta.of }}</span>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <a
                href="javascript:void(0)"
                class="nav-link nav-link-search"
                @click="showSearchBar = !showSearchBar"
              >
                <feather-icon
                  icon="SearchIcon"
                  size="21"
                  class="mr-50"
                />
                <span class="align-middle">Search</span>
              </a>
              <b-input-group
                v-if="showSearchBar"
                class="input-group-merge mr-50"
              >
                <b-form-input
                  v-model="searchQuery"
                  placeholder="Search..."
                />
                <b-input-group-append is-text>
                  <feather-icon
                    icon="XIcon"
                    class="cursor-pointer"
                    @click="searchQuery = ''; showSearchBar = !showSearchBar"
                  />
                </b-input-group-append>
              </b-input-group>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refCostCentreTable"
        class="position-relative"
        :items="fetchBudgetCostCentres"
        responsive
        :fields="tableColumns"
        primary-key="_id"
        :sort-by.sync="sortBy"
        show-empty
        no-sort-reset
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: Name -->
        <template #cell(name)="data">
          <div><span class="text-bold-black">{{ data.item.name ? data.item.name : '-' }}</span></div>
        </template>

        <template #cell(businessEntities)="data">
          <div v-if="data.item.isAllBusinessEntities">
            <span>All Entities</span>
          </div>
          <div v-else>
            <span
              v-for="(entity, entityIndex) in data.item.businessEntities"
              :key="entityIndex"
            >
              {{ entity.name }}<span v-if="data.item.businessEntities.length != entityIndex + 1">, </span>
            </span>
          </div>
        </template>

        <template #cell(departments)="data">
          <div v-if="data.item.isAllDepartments">
            <span>All Departments</span>
          </div>
          <div v-else>
            <span
              v-for="(dept, deptIndex) in data.item.departments"
              :key="deptIndex"
            >
              {{ dept.name }}<span v-if="data.item.departments.length != deptIndex + 1">, </span>
            </span>
          </div>
        </template>

        <template #cell(remarks)="data">
          <span>{{ data.item.remarks ? data.item.remarks : '-' }}</span>
        </template>

        <!-- Column: createdAt -->
        <template #cell(createdAt)="data">
          <span class="">{{ dateFormatWithTime(data.item.createdAt) }}</span>
        </template>

        <template #cell(status)="data">
          <b-badge
            :variant="`light-${resolveCostCentreStatusVariant(data.item.status)}`"
            class="text-capitalize"
          >
            {{ data.item.status }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template
          v-if="canViewThisAction('update', 'CostCenter') || canViewThisAction('delete', 'CostCenter')"
          #cell(actions)="data"
        >
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item
              v-if="canViewThisAction('update', 'CostCenter')"
              @click="openEditCostCentreModal(data.item)"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-if="canViewThisAction('delete', 'CostCenter')"
              @click="deleteEntity(data.item._id, data.item.name)"
            >
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

      </b-table>
    </b-card>

    <div
      v-if="totalCostCentres > perPage"
      class="mx-2 mb-2"
    >
      <b-row>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="12"
          class="d-flex align-items-center justify-content-center pagination-content"
        >

          <b-pagination
            v-model="currentPage"
            :total-rows="totalCostCentres"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            align="center"
            prev-text="Prev"
            next-text="Next"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
              <span>Prev</span>
            </template>
            <template #next-text>
              <span>Next</span>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>
    <!-- create new cost centre modal -->
    <b-modal
      id="create-cost-centre-modal"
      ref="create-cost-centre-modal"
      modal-class="status-update-modal"
      centered
      no-stacking
      no-close-on-backdrop
      header-class="front-modal"
      title="Create Cost Centre"
      @cancel="closeCreateNewCostCentreModal"
      @ok="submitCreateCostCentreData"
    >
      <b-form @submit.prevent="submitCreateCostCentreData">
        <validation-observer
          ref="allCreateNewCostCentreForm"
        >
          <b-form-group
            label="Name*"
            label-for="cost-centre-name"
          >
            <validation-provider
              #default="{ errors }"
              name="Name"
              vid="name"
              rules="required"
            >
              <b-form-input
                id="cost-centre-name"
                v-model="name"
                placeholder="Enter a name"
                :state="(errors.length > 0 || nameValidation) ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
              <small
                v-if="nameValidation"
                class="text-danger"
              >
                {{ nameError }}
              </small>
            </validation-provider>
          </b-form-group>
          <validation-provider
            #default="{ errors }"
            name="Usable by Entity"
            vid="businessEntity"
            rules="required"
          >
            <b-form-group
              label="Usable by Entity*"
              label-for="cost-centre-business-entity"
              :state="(errors.length > 0 || businessEntityValidation) ? false : null"
            >
              <v-select
                id="cost-centre-business-entity"
                v-model="businessEntity"
                label="name"
                placeholder="Select from list"
                multiple
                :options="businessEntityOptions"
                :reduce="name => name._id"
                :clearable="true"
                @input="updateBusinessEntityData"
              />
              <small class="text-danger">{{ errors[0] }}</small>
              <small
                v-if="businessEntityValidation"
                class="text-danger"
              >
                {{ businessEntityError }}
              </small>
            </b-form-group>
          </validation-provider>
          <validation-provider
            #default="{ errors }"
            name="Usable by Department"
            vid="department"
            rules="required"
          >
            <b-form-group
              label="Usable by Department*"
              label-for="cost-centre-department"
              :state="(errors.length > 0 || departmentValidation) ? false : null"
            >
              <v-select
                id="cost-centre-department"
                v-model="department"
                label="name"
                placeholder="Select from list"
                multiple
                :options="departmentOptions"
                :reduce="name => name._id"
                :clearable="true"
                @input="updateDepartmentData"
              />
              <small class="text-danger">{{ errors[0] }}</small>
              <small
                v-if="departmentValidation"
                class="text-danger"
              >
                {{ departmentError }}
              </small>
            </b-form-group>
          </validation-provider>
          <b-form-group
            label="Remark (optional)"
            label-for="cost-centre-remarks"
          >
            <validation-provider
              #default="{ errors }"
              name="Remark (optional)"
              vid="remarks"
              rules=""
            >
              <b-form-input
                id="cost-centre-remarks"
                v-model="remarks"
                placeholder="Add Remark"
                :state="(errors.length > 0 || remarksValidation) ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
              <small
                v-if="remarksValidation"
                class="text-danger"
              >
                {{ remarksError }}
              </small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            label="Status*"
            label-for="budgetary-status"
          >
            <validation-provider
              #default="{ errors }"
              name="Status"
              vid="status"
              rules="required"
            >
              <v-select
                id="budgetary-status"
                v-model="status"
                label="title"
                :options="statusOptions"
                :reduce="title => title.code"
                :clearable="false"
                @input="statusValidation == true ? statusValidation = false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
              <small
                v-if="statusValidation"
                class="text-danger"
              >
                {{ statusError }}
              </small>
            </validation-provider>
          </b-form-group>
        </validation-observer>
      </b-form>
      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="flat-primary"
          @click="cancel()"
        >
          Cancel
        </b-button>
        <b-button
          variant="success"
          class="custom-icon"
          :disabled="buttonStatus"
          @click="ok()"
        >
          <feather-icon
            icon="PlusIcon"
            size="16"
            class="mr-50"
          />
          <span class="align-middle">Create</span>
        </b-button>
      </template>
    </b-modal>
    <!--  /. create new cost centre modal -->
    <!-- edit cost centre modal -->
    <b-modal
      id="edit-cost-centre-modal"
      ref="edit-cost-centre-modal"
      modal-class="status-update-modal"
      centered
      no-stacking
      no-close-on-backdrop
      header-class="front-modal"
      title="Edit Cost Centre"
      @cancel="closeEditCostCentreModal"
      @ok="submitEditCostCentreData"
    >
      <b-form @submit.prevent="submitEditCostCentreData">
        <validation-observer
          ref="allEditCostCentreForm"
        >
          <b-form-group
            label="Name*"
            label-for="cost-centre-name"
          >
            <validation-provider
              #default="{ errors }"
              name="Name"
              vid="name"
              rules="required"
            >
              <b-form-input
                id="cost-centre-name"
                v-model="name"
                placeholder="Enter a name"
                :state="(errors.length > 0 || nameValidation) ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
              <small
                v-if="nameValidation"
                class="text-danger"
              >
                {{ nameError }}
              </small>
            </validation-provider>
          </b-form-group>
          <validation-provider
            #default="{ errors }"
            name="Usable by Entity"
            vid="businessEntity"
            rules="required"
          >
            <b-form-group
              label="Usable by Entity*"
              label-for="cost-centre-business-entity"
              :state="(errors.length > 0 || businessEntityValidation) ? false : null"
            >
              <v-select
                id="cost-centre-business-entity"
                v-model="businessEntity"
                label="name"
                placeholder="Select from list"
                multiple
                :options="businessEntityOptions"
                :reduce="name => name._id"
                :clearable="true"
                @input="updateBusinessEntityData"
              />
              <small class="text-danger">{{ errors[0] }}</small>
              <small
                v-if="businessEntityValidation"
                class="text-danger"
              >
                {{ businessEntityError }}
              </small>
            </b-form-group>
          </validation-provider>
          <validation-provider
            #default="{ errors }"
            name="Usable by Department"
            vid="department"
            rules="required"
          >
            <b-form-group
              label="Usable by Department*"
              label-for="cost-centre-department"
              :state="(errors.length > 0 || departmentValidation) ? false : null"
            >
              <v-select
                id="cost-centre-department"
                v-model="department"
                label="name"
                placeholder="Select from list"
                multiple
                :options="departmentOptions"
                :reduce="name => name._id"
                :clearable="true"
                @input="updateDepartmentData"
              />
              <small class="text-danger">{{ errors[0] }}</small>
              <small
                v-if="departmentValidation"
                class="text-danger"
              >
                {{ departmentError }}
              </small>
            </b-form-group>
          </validation-provider>
          <b-form-group
            label="Remark (optional)"
            label-for="cost-centre-remarks"
          >
            <validation-provider
              #default="{ errors }"
              name="Remark (optional)"
              vid="remarks"
              rules=""
            >
              <b-form-input
                id="cost-centre-remarks"
                v-model="remarks"
                placeholder="Add Remark"
                :state="(errors.length > 0 || remarksValidation) ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
              <small
                v-if="remarksValidation"
                class="text-danger"
              >
                {{ remarksError }}
              </small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            label="Status*"
            label-for="budgetary-status"
          >
            <validation-provider
              #default="{ errors }"
              name="Status"
              vid="status"
              rules="required"
            >
              <v-select
                id="budgetary-status"
                v-model="status"
                label="title"
                :options="statusOptions"
                :reduce="title => title.code"
                :clearable="false"
                @input="statusValidation == true ? statusValidation = false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
              <small
                v-if="statusValidation"
                class="text-danger"
              >
                {{ statusError }}
              </small>
            </validation-provider>
          </b-form-group>
        </validation-observer>
      </b-form>
      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="flat-primary"
          @click="cancel()"
        >
          Cancel
        </b-button>
        <b-button
          variant="success"
          class="custom-icon"
          :disabled="buttonStatus"
          @click="ok()"
        >
          <feather-icon
            icon="SaveIcon"
            size="16"
            class="mr-50"
          />
          <span class="align-middle">Save Changes</span>
        </b-button>
      </template>
    </b-modal>
    <!--  /. edit cost centre modal -->
  </div>
</template>

<script>
import {
  BCard, BRow, BButton, BCol, BForm, BFormGroup, BFormInput, BTable, BInputGroup, BInputGroupAppend,
  BDropdown, BDropdownItem, BPagination, VBTooltip, BBadge,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import { useUtils as useAclUtils } from '@/libs/acl/custom'
import { required } from '@validations'
import useCostCenterList from './useAllCostCenterList'
import costCentreStoreModule from './costCenterStoreModule'

import ToastificationContentCustom from '../ToastificationContentCustom.vue'

export default {
  components: {
    BCard,
    BRow,
    BButton,
    BCol,
    BForm,
    BFormInput,
    BTable,
    BDropdown,
    BDropdownItem,
    BPagination,
    BInputGroup,
    BInputGroupAppend,
    BFormGroup,
    BBadge,

    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContentCustom,
    vSelect,

    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },

  data() {
    return {
      buttonStatus: false,
      // eslint-disable-next-line global-require
      defaultImage: require('@/assets/images/placeholder/placeholder_image.png'),
      name: '',
      nameValidation: false,
      nameError: 'Name is required',
      businessEntity: [],
      businessEntityValidation: false,
      businessEntityError: 'Valid business entity is required',
      department: ['all'],
      departmentValidation: false,
      departmentError: 'Valid Department is required',
      remarks: '',
      remarksValidation: false,
      remarksError: 'Name is required',
      status: 'active',
      statusValidation: false,
      statusError: 'Status is required',
      businessEntityOptions: [
        {
          name: 'All Entities',
          _id: 'all',
        },
      ],
      onlyBusinessEntityOptions: [],
      departmentOptions: [
        {
          name: 'All Departments',
          _id: 'all',
        },
      ],
      onlyDepartmentOptions: [],
      statusOptions: [
        { title: 'Active', code: 'active' },
        { title: 'View Only', code: 'view only' },
      ],
      costCenterEditId: '',
      // validation rules
      required,
    }
  },

  beforeMount() {
    this.$http.get('directory/business-entities/respond-with/name-and-id')
      .then(response => {
        this.onlyBusinessEntityOptions = response.data.businessEntities
        this.businessEntityOptions = this.businessEntityOptions.concat(response.data.businessEntities ?? [])
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
    this.$http.get('inventory/departments/respond-with/name-and-id')
      .then(response => {
        this.onlyDepartmentOptions = response.data.departments
        this.departmentOptions = this.departmentOptions.concat(response.data.departments ?? [])
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  },

  methods: {
    updateBusinessEntityData() {
      this.businessEntityValidation = false
      if (this.businessEntity.includes('all')) {
        this.businessEntity = this.businessEntity.filter(el => el === 'all')
      }
    },
    updateDepartmentData() {
      this.departmentValidation = false
      if (this.department.includes('all')) {
        this.department = this.department.filter(el => el === 'all')
      }
    },
    openCreateNewCostCentreModal() {
      this.name = ''
      this.businessEntity = ['all']
      this.department = ['all']
      this.remarks = ''
      this.status = 'active'
      this.$root.$emit('bv::show::modal', 'create-cost-centre-modal', '')
    },
    openEditCostCentreModal(costCenter) {
      this.name = costCenter.name ?? ''
      this.businessEntity = costCenter.isAllBusinessEntities ? ['all'] : costCenter.businessEntities.map(a => a._id) ?? []
      this.department = costCenter.isAllDepartments ? ['all'] : costCenter.departments.map(a => a._id) ?? []
      this.remarks = costCenter.remarks ?? ''
      this.status = costCenter.status ?? ''
      this.costCenterEditId = costCenter._id
      this.$root.$emit('bv::show::modal', 'edit-cost-centre-modal', '')
    },
    closeEditCostCentreModal() {
      this.$root.$emit('bv::hide::modal', 'edit-cost-centre-modal', '')
    },
    closeCreateNewCostCentreModal() {
      this.$root.$emit('bv::hide::modal', 'create-cost-centre-modal', '')
    },
    submitCreateCostCentreData(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$refs.allCreateNewCostCentreForm.validate().then(success => {
        if (success) {
          store.commit('appConfig/UPDATE_LOADER', true)
          this.buttonStatus = true
          const formData = new FormData()
          if (this.businessEntity.includes('all')) {
            const businessEntityMapping = this.onlyBusinessEntityOptions.map(option => option._id)
            formData.append('businessEntities', JSON.stringify(businessEntityMapping))
            formData.append('isAllBusinessEntities', true)
          } else {
            formData.append('businessEntities', JSON.stringify(this.businessEntity))
            formData.append('isAllBusinessEntities', false)
          }
          if (this.department.includes('all')) {
            const departmentMapping = this.onlyDepartmentOptions.map(option => option._id)
            formData.append('departments', JSON.stringify(departmentMapping))
            formData.append('isAllDepartments', true)
          } else {
            formData.append('departments', JSON.stringify(this.department))
            formData.append('isAllDepartments', false)
          }
          formData.append('name', this.name)
          formData.append('remarks', this.remarks)
          formData.append('status', this.status)
          this.$http.post('budget/cost-center/store', formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
            .then(response => {
              this.refetchData()
              this.closeCreateNewCostCentreModal()
              this.buttonStatus = false
              // clear formData
              this.name = ''
              this.businessEntity = ''
              this.department = ''
              this.remark = ''
              this.status = 'active'
              const toastMessage = `Cost Centre <span class="text-bold-black">${response.data.data.name}</span> has been created successfully.`
              this.$toast({
                component: ToastificationContentCustom,
                props: {
                  title: 'Cost Centre is created!',
                  text: toastMessage,
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                },
              })
            })
            .catch(error => {
              if (error.data.errors) {
                this.buttonStatus = false
                error.data.errors.forEach(validationError => {
                  if (validationError.param === 'name') {
                    this.nameError = validationError.msg
                    this.nameValidation = true
                  }
                  if (validationError.param === 'businessEntity') {
                    this.businessEntityError = validationError.msg
                    this.businessEntityValidation = true
                  }
                  if (validationError.param === 'department') {
                    this.departmentError = validationError.msg
                    this.departmentValidation = true
                  }
                  if (validationError.param === 'remarks') {
                    this.remarksError = validationError.msg
                    this.remarksValidation = true
                  }
                  if (validationError.param === 'status') {
                    this.statusError = validationError.msg
                    this.statusValidation = true
                  }
                })
              } else {
                this.buttonStatus = false
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
        }
      })
    },
    submitEditCostCentreData(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$refs.allEditCostCentreForm.validate().then(success => {
        if (success) {
          store.commit('appConfig/UPDATE_LOADER', true)
          this.buttonStatus = true
          const formData = new FormData()
          if (this.businessEntity.includes('all')) {
            const businessEntityMapping = this.onlyBusinessEntityOptions.map(option => option._id)
            formData.append('businessEntities', JSON.stringify(businessEntityMapping))
            formData.append('isAllBusinessEntities', true)
          } else {
            formData.append('businessEntities', JSON.stringify(this.businessEntity))
            formData.append('isAllBusinessEntities', false)
          }
          if (this.department.includes('all')) {
            const departmentMapping = this.onlyDepartmentOptions.map(option => option._id)
            formData.append('departments', JSON.stringify(departmentMapping))
            formData.append('isAllDepartments', true)
          } else {
            formData.append('departments', JSON.stringify(this.department))
            formData.append('isAllDepartments', false)
          }
          formData.append('name', this.name)
          formData.append('remarks', this.remarks)
          formData.append('status', this.status)
          this.$http.patch(`budget/cost-center/${this.costCenterEditId}/update`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
            .then(response => {
              this.refetchData()
              this.closeEditCostCentreModal()
              this.buttonStatus = false
              const toastMessage = `Cost Centre <span class="text-bold-black">${response.data.data.name}</span> has been updated successfully.`
              this.$toast({
                component: ToastificationContentCustom,
                props: {
                  title: 'Cost Centre is edited!',
                  text: toastMessage,
                  icon: 'EditIcon',
                  variant: 'primary',
                },
              })
            })
            .catch(error => {
              if (error.data.errors) {
                this.buttonStatus = false
                error.data.errors.forEach(validationError => {
                  if (validationError.param === 'name') {
                    this.nameError = validationError.msg
                    this.nameValidation = true
                  }
                  if (validationError.param === 'businessEntity') {
                    this.businessEntityError = validationError.msg
                    this.businessEntityValidation = true
                  }
                  if (validationError.param === 'department') {
                    this.departmentError = validationError.msg
                    this.departmentValidation = true
                  }
                  if (validationError.param === 'remarks') {
                    this.remarksError = validationError.msg
                    this.remarksValidation = true
                  }
                  if (validationError.param === 'status') {
                    this.statusError = validationError.msg
                    this.statusValidation = true
                  }
                })
              } else {
                this.buttonStatus = false
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
        }
      })
    },
    deleteEntity(id, name) {
      this.$http.get(`budget/cost-center/${id}/check-existing-budget`)
        .then(response => {
          if (response.data.exists) {
            this.$swal({
              title: `${name} cannot be deleted`,
              html: 'This cost centre already in use and it is not possible to delete it. Do you want to change its status to “View only” instead? <br><br> <b>All cost centre under a “View only” cost centre will no longer be available for allocation to Purchase Requests.</b> <br><br> This action can be reversed later.',
              // eslint-disable-next-line global-require
              imageUrl: require('@/assets/images/icons/warning.png'),
              imageWidth: 80,
              imageHeight: 80,
              imageAlt: 'Custom Icon',
              reverseButtons: true,
              showCancelButton: true,
              confirmButtonText: 'Yes, change to “View only”',
              cancelButtonText: 'No, Go Back',
              customClass: {
                confirmButton: 'btn btn-primary ml-1 mt-1',
                cancelButton: 'btn btn-outline-primary mt-1',
              },
              buttonsStyling: false,
              width: 600,
            })
              .then(confirm => {
                if (confirm.value) {
                  const formData = new FormData()
                  formData.append('status', 'view only')
                  this.$http.patch(`budget/cost-center/${id}/freeze`, formData, {
                    headers: { 'Content-Type': 'multipart/form-data' },
                  })
                    .then(() => {
                      this.refetchData()
                      const toastMessage = `${name} cost centre status has been changed to View only`
                      this.$toast({
                        component: ToastificationContentCustom,
                        props: {
                          title: 'Cost Centre status is edited!',
                          text: toastMessage,
                          icon: 'ArchiveIcon',
                          variant: 'warning',
                        },
                      })
                    })
                    .catch(error => {
                      if (error.data.errors) {
                        error.data.errors.forEach(validationError => {
                          if (validationError.param === 'status') {
                            this.statusError = validationError.msg
                            this.statusValidation = true
                          }
                        })
                      } else {
                        this.$toast({
                          component: ToastificationContent,
                          props: {
                            title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                            icon: 'AlertTriangleIcon',
                            variant: 'danger',
                          },
                        })
                      }
                    })
                }
              })
          } else {
            this.$swal({
              title: 'Are you sure to delete',
              html: `<span style="font-weight: 600; font-size: 24px;">${name}?</span> <br> <br> Deleting the cost centre cannot be undone and all the data will be lost. Related budgets may not be accessible too.`,
              // eslint-disable-next-line global-require
              imageUrl: require('@/assets/images/icons/warning.png'),
              imageWidth: 80,
              imageHeight: 80,
              imageAlt: 'Custom Icon',
              reverseButtons: true,
              showCancelButton: true,
              confirmButtonText: 'Yes, Delete!',
              cancelButtonText: 'No, Go Back',
              customClass: {
                confirmButton: 'btn btn-primary ml-1',
                cancelButton: 'btn btn-outline-primary',
              },
              buttonsStyling: false,
              width: 600,
            })
              .then(result => {
                if (result.value) {
                  this.toDeletedID = id
                  this.deletedCentreName = name
                  this.deleteBudgetCostCentre()
                }
              })
          }
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
  },
  setup() {
    const showSearchBar = ref(false)
    const BUDGETING_COST_CENTRE_APP_STORE_MODULE_NAME = 'app-budgeting-cost-center'

    // Register module
    if (!store.hasModule(BUDGETING_COST_CENTRE_APP_STORE_MODULE_NAME)) store.registerModule(BUDGETING_COST_CENTRE_APP_STORE_MODULE_NAME, costCentreStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(BUDGETING_COST_CENTRE_APP_STORE_MODULE_NAME)) store.unregisterModule(BUDGETING_COST_CENTRE_APP_STORE_MODULE_NAME)
    })

    const { canViewThisAction } = useAclUtils()

    const {
      fetchBudgetCostCentres,
      tableColumns,
      perPage,
      currentPage,
      totalCostCentres,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refCostCentreTable,
      refetchData,

      toDeletedID,
      deletedCentreName,
      deleteBudgetCostCentre,
      currentDataLength,

      // UI
      resolveCostCentreStatusVariant,
    } = useCostCenterList()

    return {

      // Sidebar

      fetchBudgetCostCentres,
      tableColumns,
      perPage,
      currentPage,
      totalCostCentres,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refCostCentreTable,
      refetchData,
      showSearchBar,
      toDeletedID,
      deletedCentreName,
      deleteBudgetCostCentre,
      currentDataLength,

      // Filter
      avatarText,
      canViewThisAction,

      // UI
      resolveCostCentreStatusVariant,
    }
  },
}
</script>

<style lang="scss" scoped>
.modal .form-control {
     margin: 0;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
