import { ref, watch, computed } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import router from '@/router/index'
import ToastificationContentCustom from '../ToastificationContentCustom.vue'

// Notification

export default function useCostCentreList() {
  // Use toast
  const toast = useToast()
  const route = router.history.current

  const refCostCentreTable = ref(null)

  // Table Handlers
  const tableColumns = [
    {
      key: 'name',
      label: 'cost centre',
      sortable: true,
    },
    {
      key: 'businessEntities',
      label: 'useable by entity',
      sortable: false,
    },
    {
      key: 'departments',
      label: 'useable by department',
      sortable: false,
    },
    {
      key: 'remarks',
      label: 'remark',
      sortable: true,
    },
    {
      key: 'createdAt',
      label: 'created on',
      sortable: true,
    },
    {
      key: 'status',
      label: 'status',
      sortable: true,
    },
    {
      key: 'actions',
      label: '',
      sortable: false,
      tdClass: 'text-right',
    },
  ]
  const perPage = ref(route.query.perPage ?? process.env.VUE_APP_PER_PAGE_CONTENT)
  const totalCostCentres = ref(0)
  const currentPage = ref(route.query.page ?? 1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref(route.query.search ?? '')
  const sortBy = ref(route.query.sortBy ?? 'name')
  const isSortDirDesc = ref(route.query.sortDesc === 'true')
  const toDeletedID = ref(null)
  const deletedCentreName = ref('')
  const currentDataLength = ref(0)
  let initialLoad = true

  const dataMeta = computed(() => {
    const localItemsCount = refCostCentreTable.value ? refCostCentreTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalCostCentres.value,
    }
  })

  const refetchData = () => {
    refCostCentreTable.value.refresh()
  }

  watch([searchQuery], () => {
    currentPage.value = 1
    refetchData()
  }, { deep: true })

  watch([currentPage], () => {
    refetchData()
  }, { deep: true })

  const fetchBudgetCostCentres = (ctx, callback) => {
    store
      .dispatch('app-budgeting-cost-center/fetchBudgetCostCentres', {
        search: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
      },
      { root: true })
      .then(response => {
        const { costCenters, total } = response.data
        currentDataLength.value = costCenters.length
        callback(costCenters)
        totalCostCentres.value = total
        if (!initialLoad) {
          router.replace({
            query: {
              search: searchQuery.value,
              perPage: perPage.value,
              page: currentPage.value,
              sortBy: sortBy.value,
              sortDesc: isSortDirDesc.value,
            },
          }).catch(() => {})
        }
        initialLoad = false
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const deleteBudgetCostCentre = () => {
    store
      .dispatch('app-budgeting-cost-center/deleteBudgetCostCentre', {
        id: toDeletedID.value,
      },
      { root: true })
      .then(() => {
        refetchData()
        const toastMessage = `${deletedCentreName.value} has been deleted.`
        toast({
          component: ToastificationContentCustom,
          props: {
            title: 'Cost Centre is deleted',
            text: toastMessage,
            icon: 'Trash2Icon',
            variant: 'danger',
          },
        })
      })
      .catch(error => {
        toast({
          component: ToastificationContent,
          props: {
            title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveCostCentreStatusVariant = status => {
    if (status === 'active') return 'success'
    if (status === 'view only') return 'warning'
    return 'primary'
  }

  return {
    fetchBudgetCostCentres,
    tableColumns,
    perPage,
    currentPage,
    totalCostCentres,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refCostCentreTable,

    resolveCostCentreStatusVariant,
    refetchData,

    toDeletedID,
    deletedCentreName,
    deleteBudgetCostCentre,
    currentDataLength,
  }
}
